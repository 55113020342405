import gsap from "gsap"
export default class Contact {
    constructor(_container) {
        this.form = document.querySelector("#contact-form")
        if (!this.form) return false
        this.submitBtn = document.querySelector("#submit-form-btn")
        this.events()
    }
    
    events() {
        this.name = document.querySelector("#name")
        this.description = document.querySelector("#description")
        this.company = document.querySelector("#company")
        this.email = document.querySelector("#email")
        this.submitBtn.addEventListener("click", () => {
            if (!this.description.value) {
                this.focus(this.description)
            } else if (!this.name.value) {
                this.focus(this.name)
            } else if (!this.company.value) {
                this.focus(this.company)
            } else if (!this.email.value) {
                this.focus(this.email)
            } else {
                window.SCROLL.to(HEIGHT-1)
                this.success()
            }
        })
    }

    focus(_input) {
        window.SCROLL.to(_input.offsetTop)
        _input.focus()
    }

    success() {
        gsap.to("#send-success", {
            duration: .35,
            delay: .35,
            opacity: 1,
            display: "block"
        })
        gsap.to("#send-success .success-item", {
            duration: .45,
            opacity: 1,
            delay: .35,
            stagger: 0.15
        })
        gsap.to("#contact-section .fade-item", {
            duration: .35,
            opacity: 0,
            stagger: 0.015,
            display: "none"
        })
        gsap.to("#contact-form", {
            duration: .25,
            opacity: 0,
            display: "none"
        })
    }

}