import { gsap } from "gsap"

export default class Scroll {
    constructor() {
    	this.events()
    }

    events() {
    	// live click
        document.addEventListener('click', (event) => {
            if (!event.target.matches('.scroll-to')) return;
            event.preventDefault();
            this.scrollToHandler(event);
        }, false);
        // Autoscroll en proyectos
        document.body.addEventListener('ajax:success', (event) => {
            if (event.target.getAttribute("data-target")) {
                this.scrollToHandler(event);
            }
        });
    }

    toTop() {
        this.to(0);
    }

    to(top) {
        let scroll = {
            y: window.scrollY
        };
        const duration = Math.min(1, Math.abs(scroll.y - top) / 1000);
        gsap.to(scroll, {
            y: top,
            duration: duration,
            onUpdate: () => {
                window.scroll(0, scroll.y);
            }
        });
    }

    scrollToHandler(e) {
        let target = (e.target.getAttribute("data-target") || e.target.getAttribute("href")).replace("#", "");
        let top = window[target].offsetTop;
        this.to(top);
    }

}
