import QRCodeStyling from 'qr-code-styling'
import { gsap } from 'gsap'
export default class QR {
    constructor() {
        this.size = 0;
        this.x = 0;
        this.y = 0;
        this.scale = 1
        this.alpha = 0
        this.qrImage = null
        this.code = new QRCodeStyling({
            width: 100,
            height: 100,
            type: "svg",
            data: window.location.href,
            image: "/spaceboy-logo.svg",
            dotsOptions: {
                color: "#FFF",
                type: "square"
            },
            backgroundOptions: {
                color: "transparent",
            },
            imageOptions: {
                crossOrigin: "anonymous",
                margin: 5
            }
        })
        this.insertQRCode()
    }
    
    insertQRCode() {
        this.code.getRawData("svg").then((data) => {
            const url = URL.createObjectURL(data);
            const img = new Image()
            img.onload = () => {
                this.qrImage = img
                URL.revokeObjectURL(url)
                return this.intro() // una vez que se carga la imagen, se ejecuta
                // Esta funcionalidad 'appendSVGToDOM' tenía como objetivo ser un botón para usar share
                // Pero no responde de la mejor manera en desktop ni mobile
                // this.appendSVGToDOM(img)
            };
            img.src = url;
        });
    }

    appendSVGToDOM(img) {
        this.sharePageBtn = document.getElementById("share-page-btn");
        if (this.sharePageBtn) return this.sharePageBtn.appendChild(img)
    }

    onResize(_size) {
        this.size = _size
        let _rect = document.querySelector('#opening-title').getBoundingClientRect()
        this.x = window.HALF_X - this.size / 2
        this.y = (_rect.height / 2) - this.size / 2
    }

    show(_duration=1) {
        gsap.to(".share-container", {
            scale: 1,
            alpha: 1,
            duration: _duration,
            ease: "steps(4)"
        })
    }
    
    hide(_duration = .5) {
        gsap.to(".share-container", {
            scale: 0,
            alpha: 0,
            duration: _duration,
            ease: "steps(4)"
        })
    }

    intro() {
        this.tl = gsap.timeline({
            // paused: true
        })
        this.tl.to(this, { 
            scale: 2,
            alpha: 1,
            duration: .55,
            ease: "steps(3)"
        }, 0)
        .set(this, { 
            alpha: 0
        }, .6)
    }

    draw(_ctx) {
        if (!this.qrImage) return false
        const scaledSize = this.size * this.scale
        const offsetX = (scaledSize - this.size) / 2
        const offsetY = (scaledSize - this.size) / 2
        _ctx.save()
        _ctx.globalAlpha = this.alpha
        _ctx.drawImage(this.qrImage, this.x - offsetX, this.y - offsetY, scaledSize, scaledSize)
        _ctx.restore()
    }
}