import lightGallery from 'lightgallery'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'

export default class Gallery {
    constructor() {
        this.gallery = null
        this.container = document.querySelector(".gallery")
    }

    setup() {
        if (!this.container) return false
        return lightGallery(this.container, {
            plugins: [lgVideo, lgThumbnail, lgZoom],
            thumbnail: this.container.children.length > 1,
            licenseKey: 'E03B35F9-3212-4C64-8227-E87F9DB532AC'
            // plugins: [lgZoom, lgThumbnail, lgVideo],
            // thumbnail: this.container.dataset.thumbnail == 'true',
            // speed: 500
        })
    }

    reload() {
        this.container = document.querySelector(".gallery")
        this.gallery = this.setup()
    }

    destroy() {
        if (this.gallery) this.gallery.destroy()
        this.gallery = null
        this.container = null
    }

}