import gsap from "gsap/all"
export default class Frame {
    constructor() {
        this.container = document.querySelector("#opening-title")
        this.scale = Math.random() * 2
        this.alpha = 0
        this.header = document.getElementById('main-header');
        this.toHome = document.getElementById('to-home');
    }

    show() {
        this.scale = Math.random() * 2
        gsap.to(this, {
            scale: 1,
            alpha: .25,
            duration: 1,
            ease: "steps(12)"
        })
    }
    
    hide() {
        gsap.to(this, {
            scale: Math.random() * 2,
            alpha: 0,
            duration: .65,
            ease: "steps(12)"
        })
    }

    draw(_ctx) {
        this.context = _ctx;
        this.header_rect = this.header.getBoundingClientRect()
        const mainHeaderStyles = window.getComputedStyle(this.header);
        const padding_x = parseFloat(mainHeaderStyles.paddingLeft);
        const padding_y = parseFloat(mainHeaderStyles.paddingTop);
        let _rect = this.container.getBoundingClientRect();

        const rectWidth1 = this.header_rect.width - (padding_x * 2);
        const rectHeight1 = _rect.height - (padding_y * 2);
        // Calcular el offset para centrar el contenido escalado
        _ctx.save();
        _ctx.globalAlpha = this.alpha;
        _ctx.strokeRect(padding_x, padding_y, rectWidth1, rectHeight1);
        _ctx.restore();
        return false
    }

    drawLine(_ctx, x1, y1, x2, y2) {
        _ctx.beginPath();
        _ctx.moveTo(x1, y1);
        _ctx.lineTo(x2, y2);
        _ctx.stroke();
    }
}