import { gsap } from "gsap"
import Canvas from '../canvas'
import Text from './text'
import QR from './qr'
import Frame from './frame'
import Player from './player'
export default class Opening extends Canvas {
    constructor(_container) {
        super(_container)
        this.canvas.style.position = "relative"
        this.canvas.style.zIndex = 31
        this.setTexts()
        //
        this.player = new Player(_container)
        this.qr = new QR()
        //
        this.have_frame = this.container.classList.contains('frame')
        if (this.have_frame) {
            this.frame = new Frame()
        }
        this.addEvents()
    }

    addEvents() {
        if (this.player.events) {
            this.player.events.on("play", (delta) => this.hide(.35))
            this.player.events.on("pause", (delta) => this.show(.75))
        }
    }

    setTexts() {
        const title = this.container.querySelector('.title')
        if (title) this.title = new Text(title, {
            lineWidth: 2
        })
        const subtitle = this.container.querySelector('.subtitle')
        if (subtitle) this.subtitle = new Text(subtitle, {
            lineWidth: .5
        })
    }

    show(_duration=1) {
        if (this.frame) this.frame.show()
        gsap.delayedCall(.5, () => {
            if (this.qr) this.qr.show(_duration)
            if (this.title) this.title.show(_duration)
            if (this.subtitle) {
                gsap.delayedCall(_duration * .85, () => this.subtitle.show(_duration * .85))
            }
        })
    }

    hide(_duration) {
        if (this.qr) this.qr.hide(_duration)
        if (this.frame) this.frame.hide(_duration)
        if (this.title) this.title.hide(_duration)
        if (this.subtitle) this.subtitle.hide(_duration)
    }

    render() {
        this.clear()
        this.context.lineWidth = 1
        this.context.strokeStyle = '#FFF'
        this.context.fillStyle = '#FFF'
        //
        this.qr.draw(this.context)
        // if (this.title) this.title.draw(this.context)
        // if (this.subtitle) this.subtitle.draw(this.context)
        if (this.frame) this.frame.draw(this.context)
    }

    onResize() {
        this.setSize(this.rect.width, this.rect.height)
        this.clear()
        //
        // if (this.title) this.title.onResize()
        // if (this.subtitle) this.subtitle.onResize()
        if (this.qr) this.qr.onResize(this.rect.width * .2)
    }


}